export interface EnvConfig {
  appBaseUrl: string,
  apiBaseUrl: string,
  dealsApiBaseUrl: string,
  lendiApiBaseUrl: string,
  basePath: string,
  crmUrl: string,
  lendiUrl: string,
  googleMapApiKey: string,
  gtmId: `GTM-${string}` | false,
  optimizeId: `OPT-${string}` | false,
  lendiLandingPagesUrl: string,
  ekoUrl: string,
  opinionApi: string,
  sentryDns: string,
  ssr: boolean,
  meetSocketIO: string,
  imobiliareBaseUrl: string,
}

export enum EnviromentType {
  DEV = 'dev',
  QA = 'qa',
  DEMO = 'demo',
  PROD = 'prod',
}

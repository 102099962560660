import {useTheme} from "vuetify";
import {LendiProductsColors} from "~/plugins/vuetify";

export default function () {
    const theme = useTheme();
    const setLendiCareColors = () => {
        theme.themes.value.light.colors.primaryButton = LendiProductsColors.insurance;
        theme.themes.value.dark.colors.primaryButton = LendiProductsColors.insurance;
        theme.themes.value.light.colors.primary = LendiProductsColors.insurance;
        theme.themes.value.dark.colors.primary = LendiProductsColors.insurance;
        theme.themes.value.light.variables['text-primary'] = LendiProductsColors.insurance;
        theme.themes.value.dark.variables['text-primary'] = LendiProductsColors.insurance;
    }
    const resetLendiMortgageColors = () => {
  /*    nie działa za każdym razem...
       // @ts-ignore
        theme.themes.value.light.colors = lightThemeColors;
        theme.themes.value.light.variables = lightThemeVariables;
        // @ts-ignore
        theme.themes.value.dark.colors = darkThemeColors;
        theme.themes.value.dark.variables = darkThemeVariables;*/
        theme.themes.value.light.colors.primaryButton = LendiProductsColors.mortgage;
        theme.themes.value.dark.colors.primaryButton = LendiProductsColors.mortgage;
        theme.themes.value.light.colors.primary = LendiProductsColors.mortgage;
        theme.themes.value.dark.colors.primary = '#4598F7';
        theme.themes.value.light.variables['text-primary'] = LendiProductsColors.mortgage;
        theme.themes.value.dark.variables['text-primary'] = LendiProductsColors.mortgage;
    }
    return {
        setLendiCareColors,
        resetLendiMortgageColors
    }
}

import {ComponentObjectPropsOptions} from "@vue";
type StatePropsType = {
    readonly: boolean,
    disabled: boolean,
    error: boolean,
    loading: boolean
}
export const StateProps: ComponentObjectPropsOptions<StatePropsType> = {
  readonly: {
    default: false,
    type: Boolean,
  },
  disabled: {
    default: false,
    type: Boolean,
  },
  error: {
    default: false,
    type: Boolean,
  },
  loading: {
    default: false,
    type: Boolean,
  },
};

export function useScrollStore() {
  type ScrollPosition = {
    top?: number,
    left?: number
  };

  const savedPosition = useState<ScrollPosition | null>('scrollPosition', () => null);

  return {
    savedPosition,
  }
}
<script lang="tsx">
import {VBtn} from "vuetify/lib/components/index.mjs";
import {BtnProps} from "~/components/LComponents/Props/BtnProps";
import {IconsProps} from "~/components/LComponents/Props/IconsProps";
import {StyleProps} from "~/components/LComponents/Props/StyleProps";
import {StateProps} from "~/components/LComponents/Props/StateProps";
import {ThemeProps} from "~/components/LComponents/Props/ThemeProps";
import {SizeProps} from "~/components/LComponents/Props/SizeProps";
import {TestIdProps} from "~/components/LComponents/Props/TestIdProps";

export default defineComponent({
  components: {VBtn,},
  props: {
    ...BtnProps,
    ...IconsProps,
    ...StyleProps,
    ...StateProps,
    ...ThemeProps,
    ...SizeProps,
    ...TestIdProps,
  },
  emits: ['click',],
  setup(props, context) {
    const tempLoading = ref<boolean>(false);
    const localLoading = computed({
      get() {
        return props.loading || tempLoading.value;
      },
      set(newValue: boolean) {
        tempLoading.value = newValue;
      },
    });
    const onClick = async(event: MouseEvent) => {
      context.emit('click', event);
      if (props.click) {
        localLoading.value = true;
        try {
          await props.click();
        } finally {
          localLoading.value = false;
        }
      }
    };

    const colorByType = () => {
      switch (props.type) {
        case 'primary':
          return 'primaryButton';
        case 'secondary':
          return 'backgroundSecondary';
        case 'default':
          return 'tertiary';
        default:
          return props.type;
      }
    };

    const sizeInPX = () => {
      switch (props.size) {
        case "small":
          return 36;
        case 'default':
          return 48;
          /* TODO: Docelowo będziemy mieć small default i large*/
        case 'large':
        case 'x-large':
          return 56;
        default:
          return undefined;
      }
    };

    const fontBySize = () => {
      switch (props.size) {
        case "small":
          return 'headline-6';
          /* TODO: Docelowo będziemy mieć small default i large*/
        case 'large':
        case 'x-large':
          return 'headline-4';
        default:
          return 'headline-5';
      }
    };

    return () => {
      const content = context.slots.default ?
          <span class={['btn-text', fontBySize(),]}>
            {context.slots.default()}
          </span> : '';
      return (
          <>
            <VBtn
                height={props.height ? props.height : sizeInPX()}
                width={props.icon ? sizeInPX() : undefined}
                theme={props.theme}
                class={[
                  props.class,
                  props.variant !== 'text' && 'border',
                  'l-btn',
                ]}
                data-cy={props.dataCy}
                to={props.to}
                href={props.href}
                onClick={onClick}
                flat={props.flat}
                icon={props.icon ?? false}
                prepend-icon={props.prependIcon}
                append-icon={props.appendIcon}
                block={props.block}
                ripple={props.ripple}
                loading={localLoading.value}
                disabled={props.disabled || localLoading.value}
                selected-class={props.selectedClass}
                size={props.size}
                rel={props.rel}
                type={props.submit ? 'submit' : undefined}
                /*
                TODO: Docelowo przerobić na:
                     https://vuetifyjs.com/en/api/v-btn/#sass-btn-sizes
                     Aktualnie podwaja to cssy, co negatywnie wpływa na perfomance.
                */
                color={colorByType()}
                variant={props.variant}
            >
              {content}
            </VBtn>
          </>
      );
    };
  },

});
</script>
<style scoped>
:deep(.v-btn__content) {
  display: flex;
  align-items: center;
  justify-content: center;
}

.btn-text {
  letter-spacing: 0;
  text-transform: none;
}

.v-btn--icon {
  border-radius: 4px;
}

.border {
  border: 1px solid rgba(var(--v-border-color), var(--v-border-opacity)) !important;
}

.bg-backgroundSecondary {
  color: rgba(var(--v-theme-on-secondaryButton));
}

.v-btn--disabled {
  opacity: var(--v-disabled-opacity);
}
</style>


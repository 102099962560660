import useRatingApi from '~/service/api/useRatingApi';

const TTL = 3 * 60 * 1000;

export function useRatingStore() {
  const { getRatings } = useRatingApi();

  const cache = useState('ratingCache', () => ({
    data: null,
    expiry: 0,
  }));

  const fetchData = async () => {
    const now = Date.now();

    if (cache.value.data && now < cache.value.expiry) {
      return cache.value.data;
    }

    try {
      const response = await getRatings();
      if (!response.data.value) {
        return [];
      }

      const data = response.data.value;
      cache.value = {
        data,
        expiry: now + TTL,
      };

      return data;
    } catch (error) {
      console.error('Error fetching ratings', error);
      return [];
    }
  };

  const getCachedData = () => {
    const now = Date.now();
    if (cache.value.data && now < cache.value.expiry) {
      return cache.value.data;
    }

    return null;
  };

  return useAsyncData('ratings', fetchData, {
    server: true,
    dedupe: 'cancel',
    getCachedData,
  });
}
import {FetchContext, FetchOptions, FetchResponse, SearchParams} from 'ohmyfetch';
import {Ref, UnwrapRef} from 'vue';
import {UseFetchOptions} from 'nuxt/app';
import {assign} from 'lodash-es';
import {wait} from '~/service/Utils';
import {NitroFetchOptions, NitroFetchRequest} from 'nitropack';

enum API_METHODS {
  GET = 'GET',
  POST = 'POST',
  PUT = 'PUT',
}

export type ApiResponse<T> = { data: T, error: any, pending: Ref<boolean>, refresh: (options?: any) => void };

export async function createMockFetchResponse<T>(value: T, throwError: boolean = false): Promise<ApiResponse<Ref<T>>> {
  await wait(500);
  return {
    data: ref(value) as Ref<T>,
    error: throwError ? new Error('Test error') : null,
    pending: ref(false),
    refresh: () => {},
  };
}
export type ApiWrapper<T>= Promise<ApiResponse<T>>
export type ApiOptions = {
  baseUrl: string
}
const TIMOUT_MS = 10_000 as const;

export default function useApi(options?: ApiOptions) {
  const {apiBaseUrl,} = useEnv();
  const staticOptions: NitroFetchOptions<NitroFetchRequest> = {
    baseURL: apiBaseUrl,
    // Tutaj w przyszłości trzeba beędzie dodać obsługe tokenów itp
  };
  if (options) {
    staticOptions.baseURL = options.baseUrl;
  }

  async function FETCH<TResponse, TModel = TResponse>(url: string = '', then?: (ctx: TResponse) => TModel, params?: SearchParams, options: Partial<UseFetchOptions<any>> = {}) {
    const keySuffix = params ? JSON.stringify(params) : '';
    const key = url + keySuffix;
    // TODO: poprawić typowanie zamiast any;
    const passedOptions: any = assign(staticOptions, options || {});
    return useFetch<TResponse, string, any>(url, {
      method: API_METHODS.GET,
      // ten signal pozwala robić abort po ustalonym czasie najprawdopodobniej nie działa na wszystkich przeglądarkach
      // @ts-ignore
      signal: AbortSignal.timeout ? AbortSignal.timeout(TIMOUT_MS) : undefined,
      params,
      key,
      ...passedOptions,
    }).then(x => {
      if(then) {
        // @ts-ignore
        x.data.value = then(x.data.value);
      }
      return x;
    }) as Promise<ApiWrapper<Ref<TModel>>>;
  }


  async function api<TResponse>(method: API_METHODS, url: string, data: NitroFetchOptions<NitroFetchRequest>): Promise<TResponse> {
    const responseData = await $fetch<TResponse>(url, {
      method,
      body: data.body,
      params: data.params,
      ...staticOptions,
    });
    return responseData as TResponse;
  }

  async function GET<TResponse, TParams = SearchParams>(url: string, params?: TParams) {
    return await api<TResponse>(API_METHODS.GET, url, {
      params: {...params,},
    });
  }

  function POST<TResponse, TBody = any>(url: string, body?: TBody) {
    return api<TResponse>(API_METHODS.POST, url, {
      body: {...body,},
    });
  }
  function PUT<TResponse, TBody = any>(url: string, body: TBody) {
    return api<TResponse>(API_METHODS.PUT, url, {
      body: {...body,},
    });
  }

  return {
    FETCH,
    POST,
    GET,
    PUT,
  };
}

import {ComponentObjectPropsOptions} from 'vue';

export type TestIdProps = {
    dataCy: String;
};
export const TestIdProps: ComponentObjectPropsOptions<TestIdProps> = {
    dataCy: {
        default: undefined,
        type: String,
    },
};

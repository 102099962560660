import { SimulationFormType } from "~/enums/Simulation";

export default defineNuxtRouteMiddleware((to, from) => {
  if (to.path.startsWith('/formularz/')) {
    const pathParams = to.path.split('/');
    const NAME_PARAM_INDEX = 2;
    if (!Object.values(SimulationFormType).includes(pathParams[NAME_PARAM_INDEX])) {
      return navigateTo({name: 'formularz-type-name', params: { type: SimulationFormType.MORTGAGE, },});
    }
  }
})

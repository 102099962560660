import {ComponentObjectPropsOptions} from "@vue";
type SizePropsType = {
    height: string | number,
    width: string | number,
    maxWidth: string | number,
    maxHeight: string | number,
    minWidth: string | number,
    minHeight: string | number,
}
export const SizeProps: ComponentObjectPropsOptions<SizePropsType> = {
  height: {
    type: String || Number,
    default: undefined,
  },
  width: {
    type: String || Number,
    default: undefined,
  },
  maxWidth: {
    type: String || Number,
    default: undefined,
  },
  maxHeight: {
    type: String || Number,
    default: undefined,
  },
  minWidth: {
    type: String || Number,
    default: undefined,
  },
  minHeight: {
    type: String || Number,
    default: undefined,
  },
};

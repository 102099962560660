import {NuxtApp} from 'nuxt/dist/app/nuxt';

export default defineNuxtPlugin({
  parallel: true,
  async setup(nuxtApp: NuxtApp) {
    const basePath = useEnv()?.basePath ?? '';
    useHead({
      link: [
        {
          rel: 'preload',
          href: basePath + 'fonts/font-face.css',
          as: 'style',
        },
        {
          rel: "preload",
          href: basePath +  "fonts/opensans-variablefont_wdthwght.woff2",
          as: "font",
          crossorigin: "anonymous",
        },
        {
          rel: "preload",
          href: basePath +  "fonts/museo-700.woff2",
          as: "font",
          crossorigin: "anonymous",
        },
        {
          rel: 'stylesheet',
          href: basePath + 'fonts/font-face.css',
        },
      ],
    });
  },
});

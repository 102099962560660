<template>
  <LLayout>
    <slot/>
    <template #navbarAppend>
      <slot name="navbarAppend"/>
    </template>
  </LLayout>
</template>

<script lang="ts" setup>
import useTheme from "~/composables/useTheme";

const {resetLendiMortgageColors,} = useTheme();

onBeforeMount(async () => {
  resetLendiMortgageColors();
  await useRatingStore();
});
</script>

<style scoped lang="scss">
</style>

import { useTypedRouter } from "~/.router";
import { lowerCase } from "lodash-es";
import { encodeUrlParam } from "~/service/Utils";
import { PRODUCT_TYPE_NAME, ProductType } from "~/enums/ProductType";

export default defineNuxtRouteMiddleware((to, from) => {
  if (to.path.startsWith('/kontakt')) {
    const { routes, } = useTypedRouter();
    const pathParams = to.path.split('/');
    const cityParamIndex = 2;
    const city: string = pathParams.length >= cityParamIndex + 1 ? lowerCase(pathParams[cityParamIndex]) : '';
    if (city && city !== 'oddzialy') {
      return navigateTo({ name: '/eksperci-finansowi-city-agent', params: { city: encodeUrlParam(city), agent: encodeUrlParam(PRODUCT_TYPE_NAME[ProductType.MORTGAGE]) }, query: to.query });
    } else {
      return navigateTo({ name: routes.placowki, query: to.query,});
    }
  } else if (/null\/sdk/g.test(to.path)) {
    const { routes, } = useTypedRouter();
    return navigateTo({ name: routes.index, query: to.query,});
  } else if (/kredyty-i-pozyczki/g.test(to.path)) {
    const { routes, } = useTypedRouter();
    return navigateTo({ name: routes.informacjeOProduktachHipotecznych, query: to.query,});
  } else if (/mieszkanie-bez-wkladu/g.test(to.path)) {
    return navigateTo({ name: 'rodzinny-kredyt-mieszkaniowy', query: to.query,}, { redirectCode: 301, });
  } else if (/mieszkanie-na-start\/termin-konsultacji/g.test(to.path)) {
    return navigateTo({ name: 'kredyt-na-start-termin-konsultacji', query: to.query,}, { redirectCode: 301, });
  } else if (/formularz\/mieszkanie-na-start/g.test(to.path)) {
    return navigateTo({ name: 'formularz-type-name', params: {type: 'kredyt-na-start', name: undefined, },query: to.query,}, { redirectCode: 301, });
  } else if (/mieszkanie-na-start/g.test(to.path)) {
    return navigateTo({ name: 'kredyt-na-start', query: to.query,}, { redirectCode: 301, });
  } else if (to.path !== '/' && to.path.endsWith('/')) {
    const newPath = to.path.slice(0, -1);
    const query = Object.keys(to.query).map(key => {
        const value = `${to.query[key]}`;
        return `${key}=${encodeURI(value)}`;
      }).join('&');

    return navigateTo(`${newPath}?${query}`, { redirectCode: 301, });
  } else if (to.path.startsWith('/eksperci-kredytowi')) {
    const CITY_PARAM_INDEX = 2;
    const AGENT_PARAM_INDEX = 3;
    const pathParams = to.path.split('/');
    const city: string = pathParams.length >= CITY_PARAM_INDEX + 1 ? encodeUrlParam(pathParams[CITY_PARAM_INDEX]) : '';
    const agent: string = pathParams.length >= AGENT_PARAM_INDEX + 1
      ? encodeUrlParam(pathParams[AGENT_PARAM_INDEX])
      : encodeUrlParam(PRODUCT_TYPE_NAME[ProductType.MORTGAGE]);
    if (city) {
      return navigateTo({ name: 'eksperci-finansowi-city-agent', params: { city: city as string, agent: agent as string, }, query: to.query }, {redirectCode: 301,});
    } else {
      return navigateTo({ name: 'eksperci-finansowi', query: to.query }, {redirectCode: 301,});
    }
  } 
});

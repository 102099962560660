import {ComponentObjectPropsOptions} from "@vue";
import { mdiCloseCircleOutline } from '@mdi/js';
type IconPropsType = {
    appendIcon: string,
    icon: string | boolean,
    prependIcon: string,
    clearIcon: string,
    prependInnerIcon: string,
    appendInnerIcon: string
}
export const IconsProps: ComponentObjectPropsOptions<IconPropsType> = {
  icon: {
    default: undefined,
    type: [Boolean, String,],
  },
  appendIcon: {
    default: undefined,
    type: String,
  },
  prependIcon: {
    default: undefined,
    type: String,
  },
  clearIcon: {
    default: mdiCloseCircleOutline,
    type: String,
  },
  prependInnerIcon: {
    default: undefined,
    type: String,
  },
  appendInnerIcon: {
    default: undefined,
    type: String,
  },
};

import {LendiCareProductType} from "~/enums/LendiCareProductType";

export enum ProductType {
  MORTGAGE = 'productTypeMortgage',
  CASH = 'productTypeCash',
  SME = 'productTypeSme',
  NONBANK = 'productTypeNonBank',
  INSURANCE = 'productTypeInsurance',
  REAL_ESTATE = 'productTypeRealEstate',
  INVESTMENT = 'productTypeInvestment',
}

export enum ProspectLoanType {
  CASH = 'Cash',
  MORTGAGE = 'Mortgage',
  REAL_ESTATE = 'RealEstate',
  INSURANCE = 'Insurance',
  SME = 'Sme',
}

export enum ProductTypeQuery  {
  CASH = 'cash',
  MORTGAGE = 'mortgage',
  REAL_ESTATE = 'real_estate',
  INSURANCE = 'insurance',
  SME = 'sme'
};

export type SimulationProductType = ProductType.CASH | ProductType.MORTGAGE | ProductType.SME;

export const PRODUCT_TYPE_NAME: PartialRecord<ProductType | LendiCareProductType, string> = {
  [ProductType.MORTGAGE]: 'Kredyty hipoteczne',
  [ProductType.CASH]: 'Kredyty gotówkowe',
  [ProductType.SME]: 'Kredyty firmowe',
  [ProductType.NONBANK]: 'Kredyty pozabankowe',
  [ProductType.INSURANCE]: 'Ubezpieczenia',
  [ProductType.REAL_ESTATE]: 'Nieruchomości',
  [ProductType.INVESTMENT]: 'Inwestycje',
  [LendiCareProductType.REAL_ESTATE]: 'Ub. nieruchomości',
  [LendiCareProductType.COMMUNICATION]: 'Ub. komunikacyjne',
  [LendiCareProductType.LIFE]: 'Ub. na życie',
  [LendiCareProductType.COMPANY]: 'Ub. firmowe',
  [LendiCareProductType.TRAVEL]: 'Ub. podróżne',
  [LendiCareProductType.INVESTMENT]: 'Inwestycje',
};

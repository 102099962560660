import { useScrollStore } from "~/composables/useScrollStore";

export default defineNuxtPlugin({
  parallel: true,
  async setup (nuxtApp) {
    nuxtApp.hook('page:finish', () => {
      const {savedPosition} = useScrollStore();

      if ( document.documentElement?.style?.overflow) {
        document.documentElement.style.overflow = '';
      }
      
      if (!savedPosition.value) {
        document.documentElement.scrollTop = 0;
        return;
      }
      
      const resizeObserver = new ResizeObserver((entries) => {
        if (savedPosition.value?.top !== undefined &&
          entries[0]?.contentRect?.height &&
          (entries[0]?.contentRect?.height - screen.height >= savedPosition.value?.top)) {
          document.documentElement.scrollTop = savedPosition.value.top || 0;
          savedPosition.value = null;
          resizeObserver.unobserve(document.documentElement);
        }
      });
      
      resizeObserver.observe(document.documentElement);
    });
  },
});
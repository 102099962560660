import {EnviromentType} from '~/models/EnvConfig';

export default function useEnv() {
  const publicRuntimeConfig = useRuntimeConfig().public;
  const NUXT_ENV = publicRuntimeConfig.NUXT_ENV as EnviromentType;
  return {
    ...publicRuntimeConfig,
    env: NUXT_ENV,
    isDev: NUXT_ENV === EnviromentType.DEV,
    isPreprodOrDev: NUXT_ENV === EnviromentType.DEV || NUXT_ENV === EnviromentType.QA,
    isProd: NUXT_ENV === EnviromentType.PROD,
  };
}

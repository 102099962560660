import {ComponentObjectPropsOptions, PropType} from "vue";
import type {TypedRouteList} from '~/.router';
import type {DensityType} from '~/components/LComponents/Props/DensityType';
import type {RouteLocationRaw} from "~/types/TypedRouter";

export type BtnType = "primary" | "secondary" | "default" | "error" | 'success'
export type BtnDensityType = "default" | "comfortable" | "compact"
export type BtnVariantType = 'flat' | "text"
export type BtnSizeVariant = "x-small" | "small" | "default" | "large" | "x-large"

type BtnPropsType = {
  click: () => void,
  block: boolean,
  size: BtnSizeVariant,
  type: BtnType
  to: TypedRouteList | string,
  href: string,
  submit: boolean,

  stacked: boolean,
  flat: boolean,
  ripple: boolean,
  density: BtnDensityType,
  variant: BtnVariantType,
}
export const BtnProps: ComponentObjectPropsOptions<BtnPropsType> = {
  rel: {
    type: String as PropType<string>,
  },
  click: {
    type: Function as PropType<() => void>,
  },
  type: {
    type: String as PropType<BtnType>,
    default: 'primary',
  },
  to: {
    type: String as PropType<RouteLocationRaw | TypedRouteList | string>,
    required: false,
  },
  href: {
    type: String,
    required: false,
  },
  flat: {
    type: Boolean,
    default: true,
  },
  block: {
    type: Boolean,
    default: false,
  },
  stacked: {
    type: Boolean,
    default: false,
  },
  ripple: {
    type: Boolean,
    default: true,
  },
  density: {
    type: String as PropType<DensityType>,
    default: "default",
    required: false,
  },
  variant: {
    type: String as PropType<BtnVariantType>,
    default: 'flat',
  },
  size: {
    type: String as PropType<BtnSizeVariant>,
    default: "default",
  },
  submit: {
    type: Boolean,
    default: false,
  },
};

import revive_payload_client_MjpSMSIvob from "/vercel/path0/node_modules/.pnpm/nuxt@3.12.3_eslint@8.12.0_sass@1.63.6_typescript@5.4.5_vite@5.3.2_vue-tsc@2.0.28_xml2js@0.6.2/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_Nq3PT5Kjtp from "/vercel/path0/node_modules/.pnpm/nuxt@3.12.3_eslint@8.12.0_sass@1.63.6_typescript@5.4.5_vite@5.3.2_vue-tsc@2.0.28_xml2js@0.6.2/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_OnZB2MZpES from "/vercel/path0/node_modules/.pnpm/nuxt@3.12.3_eslint@8.12.0_sass@1.63.6_typescript@5.4.5_vite@5.3.2_vue-tsc@2.0.28_xml2js@0.6.2/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_qdralUVfCv from "/vercel/path0/node_modules/.pnpm/nuxt-site-config@2.2.15_vite@5.3.2_vue@3.4.31/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_MvW48bGzw0 from "/vercel/path0/node_modules/.pnpm/nuxt@3.12.3_eslint@8.12.0_sass@1.63.6_typescript@5.4.5_vite@5.3.2_vue-tsc@2.0.28_xml2js@0.6.2/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_LGQhLgQQDf from "/vercel/path0/node_modules/.pnpm/nuxt@3.12.3_eslint@8.12.0_sass@1.63.6_typescript@5.4.5_vite@5.3.2_vue-tsc@2.0.28_xml2js@0.6.2/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_9YdRdPyoJh from "/vercel/path0/node_modules/.pnpm/nuxt@3.12.3_eslint@8.12.0_sass@1.63.6_typescript@5.4.5_vite@5.3.2_vue-tsc@2.0.28_xml2js@0.6.2/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_eW9g1jotvS from "/vercel/path0/node_modules/.pnpm/nuxt@3.12.3_eslint@8.12.0_sass@1.63.6_typescript@5.4.5_vite@5.3.2_vue-tsc@2.0.28_xml2js@0.6.2/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_ATTgFTCuMf from "/vercel/path0/node_modules/.pnpm/@pinia+nuxt@0.5.1_typescript@5.4.5_vue@3.4.31/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/vercel/path0/.nuxt/components.plugin.mjs";
import prefetch_client_hA58cPvM2E from "/vercel/path0/node_modules/.pnpm/nuxt@3.12.3_eslint@8.12.0_sass@1.63.6_typescript@5.4.5_vite@5.3.2_vue-tsc@2.0.28_xml2js@0.6.2/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_HNecdvMGq2 from "/vercel/path0/node_modules/.pnpm/nuxt-jsonld@2.0.8_typescript@5.4.5/node_modules/nuxt-jsonld/dist/runtime/plugin.js";
import plugin_MBPWhUoa8l from "/vercel/path0/node_modules/.pnpm/@zadigetvoltaire+nuxt-gtm@0.0.13_nuxt@3.12.3_vue@3.4.31/node_modules/@zadigetvoltaire/nuxt-gtm/dist/runtime/plugin.mjs";
import preview_client_PsKmd3LLIc from "/vercel/path0/node_modules/.pnpm/@nuxthq+studio@2.0.3/node_modules/@nuxthq/studio/dist/runtime/plugins/preview.client.js";
import __typed_router_WNPrsI2W6J from "/vercel/path0/plugins/__typed-router.ts";
import fontLoader_PLbHlk3Q6u from "/vercel/path0/plugins/fontLoader.ts";
import globalVariables_zVHXWahttK from "/vercel/path0/plugins/globalVariables.ts";
import scrollToSavedPosition_client_v3IFrrgQWe from "/vercel/path0/plugins/scrollToSavedPosition.client.ts";
import vuetify_7h9QAQEssH from "/vercel/path0/plugins/vuetify.ts";
export default [
  revive_payload_client_MjpSMSIvob,
  unhead_Nq3PT5Kjtp,
  router_OnZB2MZpES,
  _0_siteConfig_qdralUVfCv,
  payload_client_MvW48bGzw0,
  navigation_repaint_client_LGQhLgQQDf,
  check_outdated_build_client_9YdRdPyoJh,
  chunk_reload_client_eW9g1jotvS,
  plugin_vue3_ATTgFTCuMf,
  components_plugin_KR1HBZs4kY,
  prefetch_client_hA58cPvM2E,
  plugin_HNecdvMGq2,
  plugin_MBPWhUoa8l,
  preview_client_PsKmd3LLIc,
  __typed_router_WNPrsI2W6J,
  fontLoader_PLbHlk3Q6u,
  globalVariables_zVHXWahttK,
  scrollToSavedPosition_client_v3IFrrgQWe,
  vuetify_7h9QAQEssH
]
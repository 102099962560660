export const lendiBreakpoints = {
  "xs": 0,
  "sm": 600,
  "md": 960,
  "lg": 1280,
  "xl": 1920,
  "xxl": 2560,
  "2xl": 2560,
};

const numbers = Object.values(lendiBreakpoints);

const nuxtImgLendiBreakpoints = {};
const tailwindLendiBreakpoints = {};

Object.keys(lendiBreakpoints).map((x, index) => {
  nuxtImgLendiBreakpoints[x] = numbers[index + 1] ?? numbers[index];
  tailwindLendiBreakpoints[x] = `${numbers[index] ?? numbers[index - 1]}px`;
});

export {
  nuxtImgLendiBreakpoints,
  tailwindLendiBreakpoints
};

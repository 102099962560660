import useApi, {type ApiWrapper} from '~/service/api/_useApi';
import {Rating, RatingType, RatingValue} from '~/models/Rating';
import {Ref} from 'vue';

export default function useRatingApi() {
  const { FETCH, PUT, } = useApi();
  const ratingBaseUrl = `/rating`;

  const getRating = async(ratingType: RatingType): ApiWrapper<Ref<Rating>> => {
    return FETCH<Rating>(`${ratingBaseUrl}/${ratingType}`);
  };

  const updateRating = (ratingType: RatingType, rating: RatingValue) => {
    return PUT<undefined, {key: RatingType, value: RatingValue}>(`${ratingBaseUrl}`, {
      key: ratingType,
      value: rating,
    });
  };

  const getRatings = async(): ApiWrapper<Ref<Rating[]>> => {
    return FETCH<Rating[]>(`${ratingBaseUrl}`);
  };

  return {
    updateRating,
    getRating,
    getRatings,
  };
}

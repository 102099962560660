import routerOptions0 from "/vercel/path0/node_modules/.pnpm/nuxt@3.12.3_eslint@8.12.0_sass@1.63.6_typescript@5.4.5_vite@5.3.2_vue-tsc@2.0.28_xml2js@0.6.2/node_modules/nuxt/dist/pages/runtime/router.options";
import routerOptions1 from "/vercel/path0/app/router.options.ts";
const configRouterOptions = {
  hashMode: false,
  scrollBehaviorType: "auto"
}
export default {
...configRouterOptions,
...routerOptions0,
...routerOptions1,
}
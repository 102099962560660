import {ComponentObjectPropsOptions, PropType} from 'vue';
type StylePropsType = {
  border: boolean | string | number,
  rounded: boolean | string | number,
  selectedClass: string
  class: string | Record<string, boolean>
}
export const StyleProps: ComponentObjectPropsOptions<StylePropsType>= {
  border: {
    type: [Boolean, String, Number,],
    default: undefined,
  },
  class: {
    type: String || Object as PropType<Record<string, boolean>>,
  },
  rounded: {
    type: [Boolean, String, Number,],
    default: false,
  },
  selectedClass: {
    type: String,
    default: undefined,
  },
};

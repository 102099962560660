export default {
  scrollBehavior: (to, from, scrollPosition) => {
    if (to.hash) {
      return {
        el: to.hash,
        behavior: 'smooth',
      };
    }

    const routesWithSavedPosition: string[] = [
      'eksperci-finansowi-city-agent'
    ];
    const {savedPosition} = useScrollStore();
    if (routesWithSavedPosition.includes(to.name)) {
      savedPosition.value = scrollPosition;
    }
  },
};

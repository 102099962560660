export const TestIds = {
    INDEX_PAGE: {
        HEADER: 'index-header',
        COMPARE_OFFERS_BTN: 'compare-bank-informations-btn',
        FLAT_FOR_START: 'flat-for-start-btn',
        BANK_AVATAR: 'bank-avatars',
    },
    EXPERTS_IN_CITY_PAGE: {
        HEADER: 'experts-in-city-header',
        PRODUCT_TYPE_DROPDOWN: 'product-type-dropdown',
    },
    BRANCHES_PAGE: {
        HEADER: 'branches-header',
    },
    BRANCHES_IN_CITY_PAGE: {
        IT: 'branches-in-city-page',
    },
    MORTGAGES_PAGE: {
        GUIDE_PANEL_ITEM: 'guide-panel-item',
    },
    MAIN_LEAD_FORM: {
        PHONE_SECTION: 'phone-section',
    },
    MORTGAGES_FORM_PAGE: {
        HEADER: 'mortgages-form-page-header',
    },
    FAQ_PANEL: {
        PANEL: 'faq-panel',
        ITEM: 'faq-panel-item',
        QUESTION: 'faq-panel-item-question',
        ANSWER: 'faq-panel-item-answer',
    },
    AGENT_PAGE: {
        CTA_BTN: 'focus-on-lead-card-btn',
        ABC_PERSONAL_INFO: 'personal-info',
        EXP_CHART: 'exp-chart',
        AGENT_CARD: (id: number) => `agent-card-${id}`,
    },
    INSURANCE_AGENT_NOT_FOUND_VIEW: {
        IT: 'agent-not-found-view',
        CALL_CENTER_BTN: 'call-center-btn',
        BRANCHES_REDIRECT_BTN: 'branches-redirect-btn',
    },
    LENDI_CARE_PHONE_CARD: {
        IT: 'lendicare-phone-card',
        BTN: 'lendicare-phone-card-btn',
    },
    COMPARE_WIDGET: {
        HEADER: 'comparer-widget-header',
        HYPOTEC_INPUT: 'hypotec-input',
        SAVINGS_INPUT: 'savings-input',
        SHOW_OFFERS_BTN: 'show-bank-informations-btn',
        OFFER_CARD: 'offer-card',
        ALERT: 'compare-widget-alert',
    },
    BRANCHES_LIST: {
        IT: 'branches-list',
        SEARCH_INPUT: 'branches-search-input',
    },
    BRANCHES_PANELS_CARD: {
        IT: 'branches-panels-card',
        BRANCH_PANEL: 'branch-panel',
        BRANCH_PANEL_ID: (id: number) => `branch-panel-${id}`,
        PHONE_INFO: 'phone-info',
        ADDRESS_INFO: 'address-info',
        AGENT_AVATAR: 'agent-avatar',
        AGENT_AVATAR_ID: (id: number) => `agent-avatar-${id}`,
    },
    LEAD_CARD: {
        IT: 'lead-card',
        FIRSTNAME_INPUT: 'lead-card-firstname-input',
        LASTNAME_INPUT: 'lead-card-lastname-input',
        EMAIL_INPUT: 'lead-card-email-input',
        PHONE_INPUT: 'lead-card-phone-input',
        ALL_AGREEMENTS_CHECKBOX: 'lead-card-all-agreements-checkbox',
        PROCESS_DATA_CHECKBOX: 'lead-card-data-process-checkbox',
        MARKETING_CHECKBOX: 'lead-card-marketing-checkbox',
        SUBMIT_BTN: 'lead-card-submit-btn',
    },
    LEAD_CATCHER: {
        IT: 'lead-catcher',
        PHONE_INPUT: 'lead-catcher-phone-input',
        SHOW_BTN: 'lead-catcher-show-btn',
        CLOSE_BTN: 'lead-catcher-close-btn',
    },
    CONSULTATIONS_FORM: {
        IT: 'consultations-form',
        CHIP_RADIO: 'consultations-form-chip-radio',
        AVAILABLE_HOURS: 'consultations-form-available-hours',
        PREV_PAGE_BTN: 'consultations-form-prev-page-btn',
        NEXT_PAGE_BTN: 'consultations-form-next-page-btn',
        SUBMIT_BTN: 'consultations-form-submit-btn',
        CHIP_RADIO_ITEM: (name: string) => `consultations-form-type-${name}`,
    },
    CONFIRM_CONSULTATION: {
        IT: 'confirm-consultation',
    },
    L_SMS_CODE_CHECK: {
        IT: 'lsms-code-check',
        SUBMIT_BTN: 'lsms-code-check-submit-btn',
    },
    CONSULTATION_SUCCESS_BANNER: {
        IT: 'consultation-success-banner',
    },
    NAVBAR: {
        IT: 'navbar',
        MENU_LIST: 'navbar-menu-list',
        PHONE_BTN: 'phone-btn',
    },
    FOOTER: {
        IT: 'footer',
    },
    L_EXPANSION_PANEL: {
        EXPANSION_PANEL: 'expansion_panel',
        EXPANSION_PANEL_TITLE: 'expansion_panel_title',
        EXPANSION_PANEL_ITEM: 'expansion_panel_item',
        EXPANSION_PANEL_TEXT: 'expansion_panel_text',
    },
    ERROR_500: 'error-500',
    HEADER: {
        MENU_ARRANGE_CONSULTATION: 'menu-arrange-consultation',
    },
    ABOUT_US_PAGE: {
        COFOUNDER_INDEX: (index: number) => `co-founder-index-${index}`,
    },
};

import {capitalize, deburr, omitBy} from "lodash-es";
import {Address} from "~/models/Address";

export const undefinedRegex = /^((?!undefined)[\s\S])*$/;
export const nullRegex = /^((?!null)[\s\S])*$/;

export const capitalizeName = (name: string): string => {
  return name ? name.split('-').map(element => capitalize(element.trim())).join('-') : '';
};

export const getFullStreetName = (address: Address): string => {
  if (!address || !address.street) {
    return '';
  }

  const STREET_REG_EXP = /^([u|a|p]+l(\.| )+)|(alej[a|e]|plac)+/i;
  const prefix = STREET_REG_EXP.test(address.street) ? '' : 'ul. ';

  return `${prefix}${address.street} ${address.streetNum || ''}${address.streetNum && address.streetLocal ? '/' + address.streetLocal :  ''}`;
};

export const formatZipCode = (zipCode?: string): string => {
  if (zipCode?.includes('-')) {
    return zipCode;
  }
  return zipCode && zipCode.length === 5 ? `${zipCode.slice(0, 2)}-${zipCode.slice(2)}` : zipCode;
};

export const getFullAddress = (address: Address): string => {
  try {
    return `${getFullStreetName(address)}, ${address.city}`;
  } catch (e) {
    return 'Błąd wczytywania adresu.';
  }
};

export const getAddressCity = (address: Address): string => {
  try {
    return `${address.city.replaceAll(' ', ' ')}`;
  } catch (e) {
    return 'Błąd wczytywania adresu.';
  }
};

export const reformatPhoneNumber = (inputString: string) => {
  // Remove all non-digit characters
  const digitsOnly = inputString.replace(/\D/g, '');

  // Split the digits into groups
  const groups = digitsOnly.match(/(\d{2})(\d{3})(\d{3})(\d{3})/);

  // If groups were successfully extracted, reformat the string
  if (groups) {
    const reformattedString = `+${groups[1]} ${groups[2]} ${groups[3]} ${groups[4]}`;
    return reformattedString;
  }

  // Return the original string if no groups were found
  return inputString;
};
export function textNormalizer(text: string): string {
  return text
      ? text.trim()
          .toLowerCase()
          .normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '')
          .replace(/\u0142/g, 'l')
      : '';
}

export const searchCompare = (search: string, text: string): boolean => {
  return textNormalizer(text).includes(textNormalizer(search));
};

type ScrollToOptions = {
  offsetY: number,
};
/**
 * @deprecated
 */
export const scrollTo = (scrollId: string, options?: ScrollToOptions) => {
  const element = document.getElementById(scrollId);
  const top = element.getBoundingClientRect().top
      + window.pageYOffset
      + (options?.offsetY ?? 0);

  window.scrollTo({
    top,
    behavior: 'smooth',
  });
};

export const wait = (duration: number) =>
    new Promise<void>(resolve => setTimeout(() => {
      resolve();
    }, duration));

export const keys = <T extends object>(obj: T): (keyof T)[] => {
    return Object.keys(obj) as (keyof T)[];
};

export const focusFirstInput = async(targetId: string, inputSelector: string = 'input:not([readonly])') => {
  // time needed to wait for the element to be rendered
  await wait(10);
  const target = window.document.getElementById(targetId);
  const input = target?.querySelector(inputSelector) as HTMLInputElement;
  if (input) {
    input.focus();
  } else {
    console.error(`No input found in ${targetId}`, target);
  }
};

export const focusFirstTextInput = async(targetId: string) => {
  focusFirstInput(targetId, 'input:not([readonly]):first-child');
};

export const createQuery = (obj: object): string => {
  const str = [];
  if (obj) {
    for (const p in obj) {
      // eslint-disable-next-line no-prototype-builtins
      if (obj[p] !== undefined && obj[p] !== null) {
        str.push(encodeURIComponent(p) + '=' + encodeURIComponent(obj[p]));
      }
    }
    return str.join('&');
  } else {
    return '';
  }
};

export const formatPrice = (value: number): string => {
  const SIZES_GAP = 3 as const;
  const sizes = ['', 'tys', 'mln', 'mld',];
  const sizeIndex = Math.floor(Math.log10(value) / SIZES_GAP);
  const price: number = Math.round(value / Math.pow(1000, sizeIndex));
  return `${price} ${sizes[sizeIndex]} zł`;
};

export function getSystem(): string {
  const userAgent = window.navigator.userAgent;
  if(userAgent.includes('Mac')) return 'Mac OS';
  else if(userAgent.includes('Windows')) return 'Windows';
  else if(userAgent.includes('Android')) return 'Android';
  else return 'iOS';
}
export function getBrowser() {
  const ua = navigator.userAgent;
  let tem,M=ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
  if(/trident/i.test(M[1])){
    tem=/\brv[ :]+(\d+)/g.exec(ua) || [];
    return {name: 'IE',version: (tem[1]||''),};
  }
  if(M[1]==='Chrome'){
    tem=ua.match(/\bOPR|Edge\/(\d+)/);
    if(tem!=null)   {return {name: 'Opera', version: tem[1],};}
  }
  M=M[2]? [M[1], M[2],]: [navigator.appName, navigator.appVersion, '-?',];
  if((tem=ua.match(/version\/(\d+)/i))!=null) {M.splice(1,1,tem[1]);}
  return {
    name: M[0],
    version: M[1],
  };
}

export const encodeUrlParam = (param: string = '') => {
  return deburr(param).toLowerCase().replace(/[ ]+/g, '-');
};

export const decodeUrlParam = (param: string = '') => {
  return param.replace(/[-]+/g, ' ');
};

export function cloneSimple<T = any>(obj: T): T {
  return JSON.parse(JSON.stringify(obj));
}

export const cleanFromFalsy = <T>(object: Object): T => {
  return omitBy(object, (value) => value === undefined || value === null) as T;
};

export const currentYear = new Date().getFullYear();

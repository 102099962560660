import {ComponentObjectPropsOptions, PropType} from 'vue';
import type {ThemeType} from '~/models/ThemeType';

type BaseColorType = 'primary' | 'success' | 'error' | 'secondary'
type ThemePropsType = {
    color: BaseColorType | string,
    bgColor: string,
    theme: ThemeType,
}
export const ThemeProps: ComponentObjectPropsOptions<ThemePropsType> = {
  bgColor: {
    default: undefined,
    type: String,
  },
  color: {
    default: "primary",
    type: String as PropType<BaseColorType | string>,
  },
  theme: {
    default: undefined,
    type: String as PropType<ThemeType>,
  },
};

import {createVuetify, VuetifyOptions} from 'vuetify';
import {aliases, mdi} from 'vuetify/lib/iconsets/mdi-svg';
import {mdiChevronUp} from '@mdi/js';
import {lendiBreakpoints} from '~/service/constants/LendiBreakpoints';

type Colors = Record<string, `#${string}`>

export const medalColors: Colors = {
    gold: '#DDB423',
    'on-gold': '#172C57',
    silver: '#BAB8B8',
    'on-silver': '#172C57',
    bronze: '#C45C1E',
    'on-bronze': '#172C57',
};

export const LendiProductsColors: Colors = {
    lendi: '#22C7FA',
    'on-lendi': '#FFFFFF',
    mortgage: '#0A73EB',
    'on-mortgage': '#FFFFFF',
    cash: '#23CD30',
    'on-cash': '#FFFFFF',
    sme: '#912AFA',
    'on-sme': '#FFFFFF',
    realEstate: '#CD6523',
    'on-realEstate': '#FFFFFF',
    insurance: '#EA0650',
    'on-insurance': '#FFFFFF',
};

export const lightThemeColors: Colors = {
  content: '#172C57',
  'on-content': '#FFFFFF',
  anchor: '#0A73EB',
  primary: '#0A73EB',
  secondary: '#172c57',
  tertiary: '#FFFFFF',
  'on-tertiary': '#172C57',
  primaryButton: LendiProductsColors.mortgage,
  secondaryButton: '#22C7FA',
  'on-secondaryButton': '#0A73EB',
  surfaceColor: '#22C7FA',
  background: '#F7F9FD',
  "on-background": "#172C57",
  surface: '#FFFFFF',
  "on-surface": "#172C57",
  warning: '#FFCC1A',
  "on-warning": "#172C57",
  error: '#FA7268',
  "on-error": "#172C57",
  success: '#5EF791',
  "on-success": "#172C57",
  ...LendiProductsColors,
  shadow: '#A2ABBE',
  'alert-warning': '#8F6F00',
  'alert-success': '#17823B',
  'alert-error': '#E31708',
  ...medalColors,
};
export const lightThemeVariables = {
  "border-color": "#172C57",
  "text-primary": '#0A73EB',
  'high-emphasis-opacity': 1,
  'medium-emphasis-opacity': 0.7,
  'disabled-opacity': 0.55,
  'low-emphasis-opacity': 0.12,
};

export const darkThemeColors = {
  content: '#FFFFFF',
  anchor: '#FFFFFF',
  'on-content': '#172C57',
  primary: '#4598F7',
  secondary: '#172c57',
  tertiary: '#FFFFFF',
  'on-tertiary': '#172C57',
  primaryButton: LendiProductsColors.mortgage,
  secondaryButton: '#22C7FA',
  'on-secondaryButton': '#FFFFFF',
  surfaceColor: '#22C7FA',
  background: '#172C57',
  "on-background": "#FFFFFF",
  surface: '#172C57',
  "on-surface": "#FFFFFF",
  warning: '#FFD74C',
  "on-warning": "#172C57",
  error: '#FC9E97',
  "on-error": "#172C57",
  success: '#8FF9B3',
  "on-success": "#172C57",
  shadow: '#050A14',
  'alert-warning': '#FFD74E',
  'alert-success': '#8FF9B3',
  'alert-error': '#FC9E97',
  ...LendiProductsColors,
  ...medalColors,
};
export const darkThemeVariables = {
  "border-color": "#22C7FA",
  "border-opacity": 0.2,
  "text-primary": '#0A73EB',
  'high-emphasis-opacity': 1,
  'medium-emphasis-opacity': 0.7,
  'disabled-opacity': 0.55,
  'low-emphasis-opacity': 0.12,
};
export const LendiVuetifyOptions: VuetifyOptions = {
  ssr: true,
  icons: {
    defaultSet: 'mdi',
    aliases: {
      ...aliases,
      collapse: mdiChevronUp,
    },
    sets: {mdi,},
  },
  display: {
    mobileBreakpoint: 'xs',
    thresholds: lendiBreakpoints,
  },
  theme: {
    themes: {
      light: {
        dark: false,
        colors: lightThemeColors,
        variables: lightThemeVariables,
      },
      dark: {
        dark: true,
        colors: darkThemeColors,
        variables: darkThemeVariables,
      },
    },
  },
};

const STYLE_NODE_NAME = 'STYLE';
const VUETIFY_THEME_STYLESHEET_ID = 'vuetify-theme-stylesheet';
const VUETIFY_THEME_STYLESHEET_CONTENT_SRART = ':root {\n      --v-theme-background';

const observeStylesheetChanges = (mutation) => {
  removeDuplicatedThemeStylesheet(mutation.addedNodes)
}

const removeDuplicatedThemeStylesheet = (items: HTMLCollection) => {
  for(const item of items) {
    if (item.nodeName === STYLE_NODE_NAME && item.id !== VUETIFY_THEME_STYLESHEET_ID && item.innerHTML.startsWith(VUETIFY_THEME_STYLESHEET_CONTENT_SRART)) {
      item.remove();
      return;
    }
  }
}

export default defineNuxtPlugin({
  parallel: true,
  async setup(nuxtApp) {
    if (process.client) {
      //usunięcie duplikatu themowych stylów dodanego juz po stronie serwera
      removeDuplicatedThemeStylesheet(window.document.head.children);
      // sprawdzenie czy nie jest dodawany duplikat themowych stylów
      const observer = new window.MutationObserver(mutations => mutations.forEach(observeStylesheetChanges))
      observer.observe(window.document.head, {
        childList: true
      })
    }
    const vuetify = createVuetify(LendiVuetifyOptions);
    nuxtApp.vueApp.use(vuetify);
  },
});


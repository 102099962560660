export enum SimulationFormType {
  MORTGAGE = 'kredyty-hipoteczne',
  CASH = 'kredyty-gotowkowe',
  CREDITWORTHINESS = 'kalkulator-zdolnosci-kredytowej',
  REFINANCING = 'kalkulator-zmiany-oprocentowania-kredytu-hipotecznego',
  SECURE_CREDIT = 'bezpieczny-kredyt',
  FLAT_FOR_START = 'kredyt-na-start',
  SME = 'kredyty-firmowe',
  BUSINESS_INSIDER = 'business-insider',
}

export enum BusinessDuration {
  LESS_THAN_YEAR = 'lessThanYear',
  BETWEEN_1_AND_2_YEARS = 'betweenOneAndTwoYears',
  BETWEEN_2_AND_3_YEARS = 'betweenTwoAndThreeYears',
  MORE_THAN_3_YEARS = 'moreThanThreeYears',
}

export enum BusinessType {
  SELF_EMPLOYMENT = 'selfEmployment',
  CIVIL_COMPANY = 'civilCompany',
  PUBLIC_COMPANY = 'publicCompany',
  PARTNERSHIP_COMPANY = 'partnershipCompany',
  PRIVATE_LIMITED_COMPANY = 'privateLimitedCompany',
  LIMITED_COMPANY = 'limitedCompany',
  JOINT_STOCK_COMPANY = 'jointStockCompany',
  SPECIAL_PURPOSE_COMPANY = 'specialPurposeCompany',
  OTHER = 'other',
}

export enum BusinessTaxType {
  FULL_ACCOUNTANCY = 'fullAccountancy',
  INCOME_AND_EXPENSE_BOOK = 'incomeAndExpenseBook',
  LUMP_SUM = 'lumpSum',
  TAX_CARD = 'taxCard',
}
